import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, share, shareReplay, startWith } from 'rxjs';

interface RouterData {
  hideNavigation: boolean;
  intendedForIframe: boolean;
  excludeAgencyLogo: boolean;
  isQuickQuoter: boolean;
}

@Injectable({ providedIn: 'root' })
export class ItkRouterDataFieldsService {
  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
  ) {}

  private readonly routeData$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map(() => this.activatedRoute),
    map((route) => {
      while (route.firstChild) {
        route = route.firstChild;
      }
      return route;
    }),
    mergeMap((route) => route.data),
    share(),
  );

  public readonly routeDataFields$ = this.routeData$.pipe(
    map(
      (x) =>
        ({
          hideNavigation: !!x['hideNavigation'],
          intendedForIframe: !!x['intendedForIframe'],
          excludeAgencyLogo: !!x['excludeAgencyLogo'],
        }) as RouterData,
    ),
    shareReplay({ refCount: true, bufferSize: 1 }),
  );

  public readonly showSubNav$ = this.routeDataFields$.pipe(
    map((x) => !x.intendedForIframe),
    startWith(true),
  );
}
