import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ItkTokenManagementServiceDeprecated } from '@itk/ng-auth';
import { ITK_NG_DJANGO_API_CLIENT } from '@itk/ng-django-api';
import { ItkDjangoApi } from '@itk/universal-django-api';
import { Observable, catchError, map, of, tap } from 'rxjs';
import { BrowserEnvConfig } from '../app.config';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private readonly token: ItkTokenManagementServiceDeprecated,
    private readonly config: BrowserEnvConfig,
    @Inject(PLATFORM_ID) private readonly pid: string,
    @Inject(ITK_NG_DJANGO_API_CLIENT) private readonly api: ItkDjangoApi,
  ) {}

  canActivate(): Observable<boolean> {
    if (!this.token.isValidToken(this.token.getAccessToken())) {
      if (isPlatformBrowser(this.pid)) {
        window.location.href = [this.config.vueApp, '/logout'].join('');
      }

      return of(false);
    }

    return this.api.member.getMember().pipe(
      map((x) => !('error' in x)),
      tap((x) => {
        if (!x && isPlatformBrowser(this.pid)) {
          window.location.href = [this.config.vueApp, '/logout'].join('');
        }
      }),
      catchError(() => of(false)),
    );
  }
}
