import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { Toolkit } from '../types';
import { defaultMetadata as toolkits } from './metadata.constants';
import { LocalStorageService } from 'ngx-localstorage';
import { FEX_TOOLKIT_CODE, ToolkitCode } from '@itk/types';

@Injectable({ providedIn: 'root' })
export class ToolkitRouteService {
  constructor(
    private readonly router: Router,
    private readonly ls: LocalStorageService,
  ) {}

  public readonly currentToolkitRoute$: Observable<Toolkit | undefined> =
    this.router.events.pipe(
      map(() => this.router.url),
      map((path) => path.split('/')[1]),
      distinctUntilChanged(),
      map((path) => toolkits.findToolkitFromSlug(`/${path}`)),
    );

  public readonly isOnToolkitRoute$ = this.currentToolkitRoute$.pipe(
    map((kit) => kit !== undefined),
  );

  getSavedToolkit(): ToolkitCode {
    return (
      this.ls.get<ToolkitCode | null | undefined>('toolkit') ?? FEX_TOOLKIT_CODE
    );
  }

  saveToolkit(tk: ToolkitCode): void {
    this.ls.set('toolkit', tk);
  }
}
